export default class PsConfig {

    static versionNo : String = '1.1';

    ///
    /// API Key to connection with Main Backend
    /// This key must equal with key from backend
    ///
    static apiKey: String = "adnisthebest";

    ///
    /// Live Mode
    /// ------------
    /// If you want to upload to server, please enable below and comment Development below
    ///
    // static fixedDomain: String = "https://dpot.in/dpot-admin"; 
    // static domain: String = "https://dpot.in/dpot-admin"; 

    ///
    /// Development Mode
    /// -----------------
    /// Before upload to server, please comment this 2 lines.
    ///
    static fixedDomain: String = "https://www.dpot.in/dpot-admin";    
    static domain: String = "https://www.dpot.in/dpot-admin";

    ///
    /// Firebase Config
    ///
    static firebaseConfig = {
        apiKey: "AIzaSyCNthf19qYhcPjhgfoeufaHsofjFYJrs1U",
        authDomain: "dpot-in.firebaseapp.com",
        databaseURL: "https://dpot-in-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "dpot-in",
        storageBucket: "dpot-in.appspot.com",
        messagingSenderId: "1008088805386",
        appId: "1:1008088805386:web:45bdde2ca3b8474bbfaff3",
        measurementId: "G-H3BEWJJRE6"            
    };
    
    
    ///
    /// Base URL * Don't update it *
    ///
    static base: String = PsConfig.domain; 
    static baseUrl: String = PsConfig.domain + '/index.php';
    static fixedPath : String = PsConfig.fixedDomain;
    
    
    /// Google Map Key
    /// ----------------
    static mapKey: String = "AIzaSyCNthf19qYhcPjhgfoeufaHsofjFYJrs1U";
    

    ///
    /// Price Format
    /// -----------------
    /// Need to change according to your format that you need
    /// E.g.
    /// "#,##0.00"   => 2,555.00
    /// "##0.00"    => 2555.00
    /// "#.00"       => 2555.00
    /// "#,###."      => 2,555
    /// "#,##0.0"    => 2555.0
    /// "###"        => 255
    static  priceFormat : string = '#,##0.0';


    ///
    /// Profile Setting for Item Grid
    /// You can hide/show user profile at item grid
    /// show == show user profile
    /// hide == hide user profile
    ///
    static showUserProfile : string = 'show';


    ///
    /// Map Filter Setting
    ///
    static  noFilterWithLocationOnMap : Boolean= false;

    ///
    /// Promotion Date Pre-define Set
    ///
    /// Type 1 == Fixed Date, Type 2 == Custom Date with user input
    static promotionDays = [
        {
            id:"1", days:7, type:1
        },
        {
            id:"2", days:14, type:1
        },
        {
            id:"3", days:30, type:1
        },
        {
            id:"4", days:60, type:1
        },
        {
            id:"5", days:1, type:2
        }
    ];

    ///
    /// Default Mile for Nearest Search
    ///
    static mile = "8";

    
    

    ///
    /// For default language change, please check
    /// LanguageFragment for language code and country code
    /// ..............................................................
    /// Language             | Language Code     | Country Code
    /// ..............................................................
    /// "English"            | "en"              | "US"
    /// "Arabic"             | "ar"              | "DZ"
    /// "India (Hindi)"      | "hi"              | "IN"
    /// "German"             | "de"              | "DE"
    /// "Spainish"           | "es"              | "ES"
    /// "French"             | "fr"              | "FR"
    /// "Indonesian"         | "id"              | "ID"
    /// "Italian"            | "it"              | "IT"
    /// "Japanese"           | "ja"              | "JP"
    /// "Korean"             | "ko"              | "KR"
    /// "Malay"              | "ms"              | "MY"
    /// "Portuguese"         | "pt"              | "PT"
    /// "Russian"            | "ru"              | "RU"
    /// "Thai"               | "th"              | "TH"
    /// "Turkish"            | "tr"              | "TR"
    /// "Chinese"            | "zh"              | "CN"
    /// ..............................................................
    ///
    static defaultLanguage = {
        languageCode : 'en',
        countryCode : 'US',
        name : 'English US',
        flag :  require('@/assets/flags/usflag.png')
    };
    static supportedLanguages = [
        { languageCode : 'en', countryCode : 'US', name : 'English US', flag: require('@/assets/flags/usflag.png')},
        { languageCode : 'ar', countryCode : 'DZ', name : 'Arabic', flag: require('@/assets/flags/dzFlag.png')},
        { languageCode : 'hi', countryCode : 'IN', name : 'Hindi', flag: require('@/assets/flags/inFlag.jpeg')},
        { languageCode : 'zh', countryCode : 'CN', name : 'Malayalam', flag: require('@/assets/flags/inFlag.jpeg')}
    ];
        

    /// 
    /// Debug Mode
    ///
    static isDebugMode : Boolean = false;

    /// 
    /// Video Setting
    ///
    static isEnableVideoSetting : Boolean = true;
    
    ///
    /// Notification Setting
    ///
    static enableNotification = 'true';

    static fcmServerKey : string = 'AAAA6rbGcAo:APA91bFwLVp0X3vZ6x9CfNNwqzoPk0HDltD23J6fBv4w6O3YELuO2mZvZsnH88lIOSt9sC4R7Pm2hBLttdWTL6sWDiSfWogTxhN4hVKLhEnUx4BXEMsWUboqltnj2-dMYf2gl7pASkps';
    
    static firebaseWebPushKeyPair : string = 'BN5T0j2hGdTEK_Mkq8Rkjr3e3iGDKpmMJeTZhGwVK6vA2K6JVYu8gUAwMRfGhi79X_5fWMyb5Dz_Fs4GdeNj0RQ';
 
    ///
    /// Adsense Setting
    ///
    static adClient = "ca-pub-2888932748259762";
    static adSlot = "9010277121";
    
}
